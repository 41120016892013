import "../styles/index.scss";
import { MDCTextField } from '@material/textfield';
import { MDCTopAppBar } from "@material/top-app-bar"; // Instantiation

if (process.env.NODE_ENV === "development") {
  require("../index.html");
} // Top App BAr


var topAppBarElement = document.querySelector(".mdc-top-app-bar");
var topAppBar = new MDCTopAppBar(topAppBarElement);
var textField = new MDCTextField(document.querySelector('.mdc-text-field'));